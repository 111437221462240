<template>
  <div>
    <div class="m-b-20">
      <h2 class="text-center p-b-10">客户分析</h2>
      <Row
        :gutter="8"
        class="m-b-5"
      >
        <i-col span="12">
          <h3>客户池</h3>
        </i-col>
        <i-col
          span="12"
          class="text-right"
        >
          <h3>{{customerPondNum}}个</h3>
        </i-col>
      </Row>
      <Row
        :gutter="8"
        class="m-b-5"
      >
        <i-col span="12">
          <h3>拜访客户数</h3>
        </i-col>
        <i-col
          span="12"
          class="text-right"
        >
          <h3>{{visitCustomeNum}}个</h3>
        </i-col>
      </Row>
      <Row
        :gutter="8"
        class="m-b-5"
      >
        <i-col span="12">
          <h3>签约额</h3>
        </i-col>
        <i-col
          span="12"
          class="text-right"
        >
          <h3>{{fomartMoney(signingAmount)}}</h3>
        </i-col>
      </Row>
      <Row
        :gutter="8"
        class="m-b-5"
      >
        <i-col span="12">
          <h3>转化率</h3>
        </i-col>
        <i-col
          span="12"
          class="text-right"
        >
          <h3>{{conversionRate}}%</h3>
        </i-col>
      </Row>
      <Row
        :gutter="8"
        class="m-b-5"
      >
        <i-col span="12">
          <h3>占总经营额</h3>
        </i-col>
        <i-col
          span="12"
          class="text-right"
        >
          <h3>{{proportion}}%</h3>
        </i-col>
      </Row>
    </div>
    <div class="m-b-10">
      <h2 class="text-center p-b-10">客户价值标签</h2>
      <div
        id="chart"
        style="width:100%;height:260px;"
        class="m-b-10"
      ></div>
      <Table
        class="nonbackground"
        size="small"
        :columns="customerValueTagColumns"
        :data="customerValueTagData"
      ></Table>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { toMoney } from '@/utils/wnumb_own'
import { countMicroNumberGb, countRecordNumberGb, listMicroBusinessConvertRate, listMicroBusinessAmountRate, listMicroBusinessValueClassification } from '@/api/dw/micro'
import { countPublisherContractAmountByOrderType } from '@/api/dw/datav'
export default {
  data () {
    return {
      customerPondNum: 0,
      visitCustomeNum: 0,
      signingAmount: 0,
      conversionRate: 0,
      proportion: 0,
      customerValueTagData: [],
      customerValueTagColumns: [
        { title: '标签类型', align: 'left', key: 'name', ellipsis: true, tooltip: true },
        { title: '数量', align: 'center', key: 'number' },
        {
          title: '占比',
          align: 'right',
          key: 'rate',
          render: (h, params) => {
            return h('span', `${params.row.rate} %`)
          }
        }
      ]
    }
  },
  computed: {
    publisherId () {
      return this.$store.getters.token.userInfo.publisherId
    },
    dateRange () {
      return this.$store.state.situationWeb.dateRange
    }
  },
  methods: {
    fomartMoney (number) {
      return toMoney(number)
    },
    /**
     * 获取客户池数量
     */
    getCustomerPondData () {
      countMicroNumberGb({ publisherId: this.publisherId }).then(res => {
        if (res && !res.errcode) {
          this.customerPondNum = res.length ? res[0].number : 0
        }
      })
    },
    /**
     * 获取拜访客户数
     */
    getVistCustomerData () {
      const query = {
        publisherId: this.publisherId,
        startDate: this.dateRange.startDate,
        endDate: this.dateRange.endDate
      }
      countRecordNumberGb(query).then(res => {
        if (res && !res.errcode) {
          this.visitCustomeNum = res.length ? res[0].number : 0
        }
      })
    },
    /**
     * 获取签约额数据
     */
    getSigningAmountData () {
      const query = {
        publisherId: this.publisherId,
        startDate: this.dateRange.startDate,
        endDate: this.dateRange.endDate,
        orderType: 3,
        companyId: null

      }
      countPublisherContractAmountByOrderType(query).then(res => {
        if (res && !res.errcode) {
          this.signingAmount = res.length ? res[0].value : 0
        }
      })
    },
    /**
     * 获取转化率
     */
    getConversionRateData () {
      const query = {
        publisherId: this.publisherId,
        startDate: this.dateRange.startDate,
        endDate: this.dateRange.endDate
      }
      listMicroBusinessConvertRate(query).then(res => {
        if (res && !res.errcode) {
          this.conversionRate = res.length ? res[0].value : 0
        }
      })
    },
    /**
     * 占总经营额比率
     */
    getProportionData () {
      const query = {
        publisherId: this.publisherId,
        startDate: this.dateRange.startDate,
        endDate: this.dateRange.endDate
      }
      listMicroBusinessAmountRate(query).then(res => {
        if (res && !res.errcode) {
          this.proportion = res.length ? res[0].value : 0
        }
      })
    },
    /**
     * 获取客户价值标签数据
     */
    getCustomerValueTagData () {
      const query = {
        publisherId: this.publisherId,
        startDate: this.dateRange.startDate,
        endDate: this.dateRange.endDate
      }
      listMicroBusinessValueClassification(query).then(res => {
        if (res && !res.errcode) {
          this.customerValueTagData = res
        } else {
          this.customerValueTagData = []
        }
        const chartData = this.customerValueTagData.map(x => {
          return { value: x.rate, name: x.name }
        })
        this.initChart(chartData)
      })
    },
    initChart (data = []) {
      this.$nextTick(() => {
        let chart = echarts.getInstanceByDom(document.getElementById('chart'))
        if (chart == null) { // 如果不存在，就进行初始化
          chart = echarts.init(document.getElementById('chart'))
        }
        const option = {
          tooltip: {
            trigger: 'item',
            formatter: '{b}: {d}%'
          },
          series: [
            {
              type: 'pie',
              radius: ['40%', '50%'],
              labelLine: {
                length: 30
              },
              label: {
                formatter: '{b}\n{d}% '
              },
              data
            }
          ]
        }

        chart.setOption(option, true)
        window.addEventListener('resize', function () {
          chart.resize()
        })
      })
    }
  },
  watch: {
    dateRange: {
      deep: true,
      immediate: true,
      handler (val) {
        if (val && val.startDate && val.endDate) {
          this.getCustomerPondData()
          this.getVistCustomerData()
          this.getSigningAmountData()
          this.getConversionRateData()
          this.getProportionData()
          this.getCustomerValueTagData()
        }
      }
    }
  }
}
</script>
